import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from 'react-materialize';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { Spinner } from 'reactstrap';

import { Row, Col } from '../../../styles/grid';

import { getOrderRequestStatusByTab } from '../../../utils/utils';
import px2vw from '../../../utils/responsiveness/px2vw';

import {
  formatOrderNumber,
  formatCurrencyBRL,
  formatCreateDateTimeOrder,
} from '../../../utils/utils';

import OrderDetailModal from '../../../components/OrderDetailModal';
import OrderStatusBox from '../../../components/OrderStatusBox';
import SchedulingInfoBox from '../../../components/SchedulingInfoBox';
import ConfirmPreparingSchedulingOrder from '../../../components/ConfirmPreparingSchedulingOrder';
import check from '../../../assets/images/checkbox/check@2x.png';

import {
  Content,
  Hr,
  TableRow,
  ErrorText,
  OrderImage,
  ChangeStatusButton,
  NextStatusIcon,
  PaginateContainer,
  ButtonCheckEmpty,
  ImageContainer,
} from './styles';
import {
  ClockImage,
  OrdersRowText,
  OrderTypeBox,
  SeeOrderBox,
} from '../../_layouts/styles/orders';

import { preto } from '../../../styles/colors';

import {
  setCurrOrderStatusData,
  setCurrOrderConfigs,
  updateOrderStatusRequest,
  getOrdersPaginatedRequest,
  updateIdsList,
  cancelOrderRequest,
} from '../../../store/modules/orders/actions';
import OrdersMassActionsPopUp from '../../../components/OrdersMassActionsPopUp';
import OrdersMassActionsMenu from '../../../components/OrdersMassActionsMenu';
import { ModalActions, ModalPrimaryButton, ModalPrimaryButtonText, ModalSecundaryButton, ModalSecundaryButtonText } from '../../../components/Modal/styles';
import { CloseIcon, ConfirmCancelOrder, ConfirmCancelOrderText } from '../../../components/OrderDetailModal/styles';
import Modal from '../../../components/Modal';
import { LoadingBox } from '../../_layouts/styles/common';

export default function OrderTable({ data, selectedTab, pagination }) {
  const [currOrdersList, setCurrOrdersList] = useState(data);
  const [currPaginationData, setCurrPaginationData] = useState(pagination);
  const deliveryActive = useSelector(
    (state) => state.user.user.advertiserInfo.is_using_delivery
  );
  const takeAwayActive = useSelector(
    (state) => state.user.user.advertiserInfo.is_using_take_away
  );
  const newAcceptedOrders = useSelector((state) => state.orders.newAccepted);
  const newAcceptedPagination = useSelector(
    (state) => state.orders.newAcceptedPagination
  );
  const preparingOrders = useSelector((state) => state.orders.preparing);
  const preparingPagination = useSelector(
    (state) => state.orders.preparingPagination
  );
  const deliveringReadyTakeAwayOrders = useSelector(
    (state) => state.orders.deliveringReadyTakeAway
  );
  const deliveringReadyTakeAwayPagination = useSelector(
    (state) => state.orders.deliveringReadyTakeAwayPagination
  );
  const finishedOrders = useSelector((state) => state.orders.finished);
  const finishedPagination = useSelector(
    (state) => state.orders.finishedPagination
  );
  const currOrderStatusData = useSelector(
    (state) => state.orders.currOrderStatusData
  );
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const [orderDetailIndex, setOrderDetailIndex] = useState('');
  const [
    confirmPreparingSchedulingOrderModal,
    setConfirmPreparingSchedulingOrderModal,
  ] = useState(false);
  const advertiserUser = useSelector((state) => state.user.user);
  const alwaysPrintOrder = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_print_order_invoice_accept_order
  );
  const alwaysSendWhatsappMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );
  const getOrdersSuccess = useSelector(
    (state) => state.orders.getOrdersSuccess
  );
  const getOrdersPaginatedLoading = useSelector(
    (state) => state.orders.getOrdersPaginatedLoading
  );
  const getOrdersPaginatedSuccess = useSelector(
    (state) => state.orders.getOrdersPaginatedSuccess
  );
  const updateOrderStatusSuccess = useSelector(
    (state) => state.orders.updateOrderStatusSuccess
  );
  const cancelOrderLoading = useSelector(
    (state) => state.orders.cancelOrderLoading
  );
  const cancelOrderSuccess = useSelector(
    (state) => state.orders.cancelOrderSuccess
  );
  const idsListToMassAction = useSelector(
    (state) => state.orders.idsListToMassAction
  );
  const [isMassOrderCanceling, setIsMassOrderCanceling] = useState(false);
  const [confirmCancelOrderModal, setConfirmCancelOrderModal] = useState(false);
  const [ordersMassActionsPopUpVisible, setOrdersMassActionsPopUpVisible] =
    useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getOrdersPaginatedSuccess || getOrdersSuccess) {
      let paginationData = pagination;
      let ordersData = currOrdersList;

      switch (selectedTab) {
        case 'tabNew':
          paginationData = newAcceptedPagination;
          ordersData = newAcceptedOrders;
          break;
        case 'tabPreparing':
          paginationData = preparingPagination;
          ordersData = preparingOrders;
          break;
        case 'tabDeliveringReadyTakeAway':
          paginationData = deliveringReadyTakeAwayPagination;
          ordersData = deliveringReadyTakeAwayOrders;
          break;
        case 'tabFinished':
          paginationData = finishedPagination;
          ordersData = finishedOrders;
          break;
        default:
          break;
      }

      setCurrPaginationData(paginationData);
      setCurrOrdersList(ordersData);
    }
  }, [getOrdersPaginatedSuccess, getOrdersSuccess]);

  useEffect(() => {
    if (cancelOrderSuccess) {
      handleOpenCloseCancelOrderModal();
    }
  }, [cancelOrderSuccess]);

  function handleOpenCloseCancelOrderModal() {
    setConfirmCancelOrderModal(!confirmCancelOrderModal);
  }

  function handleChangePage(pageSelected) {
    dispatch(
      getOrdersPaginatedRequest(
        getOrderRequestStatusByTab(selectedTab),
        pageSelected.selected + 1
      )
    );
  }

  function handleOrderDetail(orderDetailIdx) {
    setOrderDetailIndex(orderDetailIdx);
    setOrderDetailModal(true);
  }

  function handleOrderDetailModalResponse() {
    setOrderDetailModal(false);
    setOrderDetailIndex('');
  }

  // Handle the response for confirm scheduling preparing order modal
  function handleConfirmPreparingSchedulingModal() {
    setConfirmPreparingSchedulingOrderModal(false);
    setOrderDetailIndex('');
  }

  function handleChangeStatus(orderStatusData, orderDetailIdx, orderId, cancel = false) {
    if (
      idsListToMassAction.length > 1 &&
      idsListToMassAction.includes(orderId)
    ) {
      setOrdersMassActionsPopUpVisible(true);
      setIsMassOrderCanceling(cancel);
    } else {
      dispatch(setCurrOrderStatusData(orderStatusData));
      dispatch(setCurrOrderConfigs(alwaysPrintOrder, alwaysSendWhatsappMsg));

    // If user request do cancel the order
      if (cancel) {
        handleOpenCloseCancelOrderModal();
      // If the order was accepted (scheduling orders) verify if necessary to display the confirm modal
      } else if (orderStatusData.status === 'accepted') {
        // Define the average time for delivery based on the delivery type
        let deliveryTypeTimeAvg = 0;
        if (orderStatusData.order.delivery_type === 'delivery') {
          deliveryTypeTimeAvg = advertiserUser.advertiserInfo.delivery_time_avg;
        } else {
          deliveryTypeTimeAvg =
            advertiserUser.advertiserInfo.take_away_time_avg;
        }
        // If the time scheduled for the order is more then the average time, show the confirmation popup
        if (
          // eslint-disable-next-line no-underscore-dangle
          (moment(orderStatusData.order.scheduling_start_at, 'DD/MM/YYYY HH:NN')
            ._d -
            new Date()) /
            60000 >
          deliveryTypeTimeAvg
        ) {
          setOrderDetailIndex(orderDetailIdx);
          setConfirmPreparingSchedulingOrderModal(true);
        } else {
          dispatch(updateOrderStatusRequest(orderStatusData, 'preparing'));
        }
        // Other orders status
      } else {
        let newStatus = null;

        if (orderStatusData.status === 'new') {
          newStatus = 'accepted';
        } else if (
          orderStatusData.status === 'preparing' &&
          orderStatusData.order.delivery_type === 'delivery'
        ) {
          newStatus = 'delivering';
        } else if (
          orderStatusData.status === 'preparing' &&
          orderStatusData.order.delivery_type === 'take_away'
        ) {
          newStatus = 'ready_take_away';
        } else if (
          orderStatusData.status === 'delivering' ||
          orderStatusData.status === 'ready_take_away'
        ) {
          newStatus = 'finished';
        }
        if (newStatus) {
          dispatch(updateOrderStatusRequest(orderStatusData, newStatus));
        }
      }
    }
  }

  function performCancelOrderAction() {
    dispatch(cancelOrderRequest(currOrderStatusData, 'canceled'));
  }

  // Close Order Detail Modal
  useEffect(() => {
    if (updateOrderStatusSuccess || cancelOrderSuccess) {
      handleOrderDetailModalResponse();
    }
  }, [updateOrderStatusSuccess, cancelOrderSuccess]);

  return (
    <>
      {currOrdersList?.length > 1 && selectedTab !== 'tabFinished' && (
        <OrdersMassActionsMenu ordersListByCurrentTab={currOrdersList} />
      )}

      <Col padding="0 15px" alignCenter justifyCenter>
        {!getOrdersPaginatedLoading &&
          currOrdersList?.length > 0 &&
          currOrdersList?.map((orderStatus, index) => (
            <Fragment key={orderStatus.id}>
              <TableRow alignCenter justifyStart>
                <Content
                  spaceAround
                  padtop={px2vw(3)}
                  padbottom={px2vw(3)}
                  width={px2vw(295)}
                  cursor="pointer"
                >
                  <Row alignCenter width="auto">
                    {/* Check for Mass Action */}
                    {!['finished', 'canceled'].includes(orderStatus.status) &&
                      currOrdersList?.length > 1 && (
                        <Col width="auto" height="auto">
                          <ButtonCheckEmpty
                            onClick={() => {
                              if (
                                !idsListToMassAction.includes(
                                  orderStatus.order_id
                                )
                              ) {
                                dispatch(
                                  updateIdsList([
                                    ...idsListToMassAction,
                                    orderStatus.order_id,
                                  ])
                                );
                              } else {
                                dispatch(
                                  updateIdsList(
                                    idsListToMassAction.filter(
                                      (orderId) =>
                                        orderId !== orderStatus.order_id
                                    )
                                  )
                                );
                              }
                            }}
                          >
                            <ImageContainer
                              checkVisible={idsListToMassAction.includes(
                                orderStatus.order_id
                              )}
                              margtop={px2vw(-7)}
                            >
                              <img
                                width="100%"
                                height="100%"
                                src={check}
                                alt="Check icon"
                              />
                            </ImageContainer>
                          </ButtonCheckEmpty>
                        </Col>
                      )}

                    <Col alignStart width="auto" height="auto">
                      <Row justifyStart height="auto" width="auto">
                        <OrderImage
                          src={require('../../../assets/images/orders/orders_wo_border.png')}
                        />
                        <OrdersRowText
                          isBold
                          margtop={px2vw(4)}
                          margleft={px2vw(2)}
                        >
                          {`Pedido ${formatOrderNumber(orderStatus?.order_id)}`}
                        </OrdersRowText>
                      </Row>
                      <Row justifyStart width={px2vw(160)}>
                        <OrdersRowText
                          fontSize={px2vw(11)}
                          clientName
                          title={
                            orderStatus?.order?.clientInfo?.name ||
                            orderStatus?.order?.clientInfo?.user?.email
                          }
                        >
                          {`Cliente: ${
                            orderStatus?.order?.clientInfo?.name ||
                            orderStatus?.order?.clientInfo?.user?.email
                          }`}
                        </OrdersRowText>
                      </Row>
                      <Row margtop={px2vw(10)} width={px2vw(130)}>
                        <ClockImage
                          src={require('../../../assets/images/clock/clock@2x.png')}
                          size={px2vw(10)}
                          margtop={px2vw(10)}
                        />
                        <OrdersRowText margleft={px2vw(5)} fontSize={px2vw(10)}>
                          {formatCreateDateTimeOrder(
                            orderStatus.new_at || orderStatus.pending_payment_at
                          )}
                        </OrdersRowText>
                      </Row>
                    </Col>
                    <SeeOrderBox
                      margleft={px2vw(30)}
                      onClick={() => {
                        handleOrderDetail(index);
                      }}
                    >
                      <OrdersRowText fontSize={px2vw(9)}>
                        {'Ver pedido >'}
                      </OrdersRowText>
                    </SeeOrderBox>
                  </Row>
                </Content>
                <Content alignCenter width={px2vw(170)}>
                  <Col width="auto">
                    <OrdersRowText isBold fontSize={px2vw(14)}>
                      {formatCurrencyBRL(
                        orderStatus.order.total_amount.toFixed(2),
                        {}
                      )}
                    </OrdersRowText>
                    <OrderTypeBox orderType={orderStatus.order.delivery_type}>
                      {orderStatus.order.delivery_type === 'delivery'
                        ? 'Delivery'
                        : 'Retirada'}
                    </OrderTypeBox>
                  </Col>
                </Content>
                <Col>
                  <Row 
                    height="auto" 
                    alignCenter
                  >
                    <Col 
                      width="auto"
                      margright={px2vw(5)}
                      justifyCenter
                      >
                      {/* Scheduling Info */}
                      {orderStatus.order.order_type === 'scheduled' && (
                        <SchedulingInfoBox
                          schedulingOrderDateTime={orderStatus.order}
                          padtop="0px"
                          width={px2vw(250)}
                          height={px2vw(32)}
                          fs={px2vw(12)}
                          margbottom={px2vw(4)}
                        />
                      )}
                      <OrderStatusBox orderStatusData={orderStatus} />
                    </Col>
                    <Content alignCenter width={px2vw(260)}>
                      {orderStatus.status !== 'canceled' &&
                        orderStatus.status !== 'finished' && (
                          <Col>
                            <ChangeStatusButton
                              onClick={() =>
                                handleChangeStatus(
                                  orderStatus,
                                  index,
                                  orderStatus?.order_id
                                )
                              }
                            >
                              <NextStatusIcon margtop={px2vw(-3)} />
                              {orderStatus.status === 'new' && 'Aceitar pedido'}
                              {orderStatus.status === 'accepted' &&
                                'Iniciar preparo'}
                              {orderStatus.status === 'preparing' &&
                                orderStatus.order.delivery_type === 'delivery' &&
                                'Saiu para entrega'}
                              {orderStatus.status === 'preparing' &&
                                orderStatus.order.delivery_type === 'take_away' &&
                                'Pronto para retirada'}
                              {(orderStatus.status === 'delivering' ||
                                orderStatus.status === 'ready_take_away') &&
                                'Concluir pedido'}
                            </ChangeStatusButton>
                            <ModalSecundaryButton
                              margtop={px2vw(10)}
                              width={px2vw(200)}
                              height={px2vw(40)}
                              onClick={() =>
                                handleChangeStatus(
                                  orderStatus,
                                  index,
                                  orderStatus?.order_id,
                                  true
                                )
                              }
                            >
                              <ModalSecundaryButtonText fontSize={px2vw(13)}>
                                <CloseIcon />
                                Cancelar pedido
                              </ModalSecundaryButtonText>
                            </ModalSecundaryButton>
                          </Col>
                      )}
                      {orderStatus.status === 'canceled' &&
                        orderStatus.new_at === null &&
                        orderStatus.pending_payment_at !== null &&
                        orderStatus.order.paymentMethod.slug ===
                          'pix_chave' && (
                          <OrdersRowText
                            margleft={px2vw(10)}
                            fontSize={px2vw(9.5)}
                          >
                            Pedido cancelado automaticamente após 1 hora da sua
                            criação, porque o cliente não confirmou o pagamento
                            no cardápio digital
                          </OrdersRowText>
                        )}
                      {orderStatus.status === 'canceled' &&
                        orderStatus.canceled_at !== null &&
                        orderStatus.order.paymentMethod.slug ===
                          'pix_copia_cola' && orderStatus.is_manual_refund_needed && (
                          <OrdersRowText
                            margleft={px2vw(10)}
                            fontSize={px2vw(9.5)}
                          >
                            Não foi possível fazer a devolução automática do valor pago pelo cliente. 
                            Você deverá fazer manualmente.
                          </OrdersRowText>
                        )}
                      {orderStatus.status === 'canceled' &&
                        orderStatus.canceled_at !== null &&
                        orderStatus.order.paymentMethod.slug ===
                          'pix_copia_cola' && 
                          orderStatus.pending_refund_at !== null && orderStatus.refunded_at === null  && (
                          <OrdersRowText
                            margleft={px2vw(10)}
                            fontSize={px2vw(9.5)}
                          >
                            A devolução automática do valor pago pelo cliente está em processamento.
                          </OrdersRowText>
                        )}
                      {orderStatus.status === 'canceled' &&
                        orderStatus.canceled_at !== null &&
                        orderStatus.order.paymentMethod.slug ===
                          'pix_copia_cola' && 
                          orderStatus.pending_refund_at !== null && orderStatus.refunded_at !== null && (
                          <OrdersRowText
                            margleft={px2vw(10)}
                            fontSize={px2vw(9.5)}
                          >
                            Valor devolvido automaticamente para o cliente com sucesso!
                          </OrdersRowText>
                        )}
                    </Content>
                  </Row>
                </Col>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ))}
        {!getOrdersPaginatedLoading && currOrdersList.length === 0 && (
          <Col height="20vh">
            <Row alignCenter justifyCenter>
              {/* TODO: Ajustar tamanho do icone */}
              <Icon large>sentiment_very_dissatisfied</Icon>
              <ErrorText>
                {selectedTab === 'tabNew' &&
                  `Você não tem novos pedidos a serem exibidos.`}
                {selectedTab === 'tabPreparing' &&
                  `Você não tem pedidos em preparo a serem exibidos.`}
                {selectedTab === 'tabDeliveringReadyTakeAway' &&
                  deliveryActive &&
                  !takeAwayActive &&
                  `Você não tem pedidos em entrega a serem exibidos.`}
                {selectedTab === 'tabDeliveringReadyTakeAway' &&
                  takeAwayActive &&
                  !deliveryActive &&
                  `Você não tem pedidos prontos para retirada a serem exibidos.`}
                {selectedTab === 'tabDeliveringReadyTakeAway' &&
                  deliveryActive &&
                  takeAwayActive &&
                  `Você não tem pedidos em entrega ou prontos para retirada a serem exibidos.`}
                {selectedTab === 'tabFinished' &&
                  `Você não tem pedidos finalizados a serem exibidos.`}
              </ErrorText>
            </Row>
          </Col>
        )}
        {getOrdersPaginatedLoading && (
          <Col height="20vh">
            <Col alignCenter justifyCenter>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />
            </Col>
          </Col>
        )}
        {/* Pagination Controls */}
        {currOrdersList?.length > 0 && (
          <Row justifyEnd>
            <PaginateContainer>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                breakLabel={'...'}
                pageCount={currPaginationData.lastPage}
                marginPagesDisplayed={3}
                pageRangeDisplayed={2}
                onPageChange={handleChangePage}
                containerClassName="pagination"
                pageClassName="page"
                activeClassName="active"
                breakClassName="break_cn"
                previousClassName="previous_cn"
                nextClassName="next_cn"
                disabledClassName="disabled_cn"
                previousLinkClassName="previous_a_cn"
                nextLinkClassName="next_a_cn"
                disabledLinkClassName="disabled_a_cn"
              />
            </PaginateContainer>
          </Row>
        )}
      </Col>
      {/* Order Detail Modal - Table click - DO NOT wrap this component to not be rendered */}
      <OrderDetailModal
        isVisible={orderDetailModal}
        orders={data}
        hasNavigation
        orderIndex={orderDetailIndex}
        onModalResponse={() => handleOrderDetailModalResponse()}
        onScreen="pedidos"
        orderStatusRequest={selectedTab}
      />
      {/* Confirm cancel order */}
      {confirmCancelOrderModal && (
        <Modal
          isVisible={confirmCancelOrderModal}
          width={px2vw(340)}
          height={px2vw(190)}
        >
          <ConfirmCancelOrder>
            <ConfirmCancelOrderText>
              Tem certeza que deseja cancelar o pedido?
            </ConfirmCancelOrderText>
            <ModalActions>
              <ModalSecundaryButton
                marginRight={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={handleOpenCloseCancelOrderModal}
                disabled={cancelOrderLoading}
              >
                <ModalSecundaryButtonText>Não</ModalSecundaryButtonText>
              </ModalSecundaryButton>
              <ModalPrimaryButton
                margleft={px2vw(20)}
                margtop={px2vw(25)}
                width={px2vw(120)}
                type="button"
                onClick={performCancelOrderAction}
                disabled={cancelOrderLoading}
              >
                {cancelOrderLoading ? (
                  <LoadingBox>
                    <Spinner
                      style={{
                        width: '1.8rem',
                        height: '1.8rem',
                        color: preto,
                      }}
                    />
                  </LoadingBox>
                ) : (
                  <ModalPrimaryButtonText>Sim</ModalPrimaryButtonText>
                )}
              </ModalPrimaryButton>
            </ModalActions>
          </ConfirmCancelOrder>
        </Modal>
      )}
      {/* Confirm preparing scheduling order */}
      {confirmPreparingSchedulingOrderModal && (
        <ConfirmPreparingSchedulingOrder
          isVisible={confirmPreparingSchedulingOrderModal}
          orders={data}
          orderIndex={orderDetailIndex}
          onModalResponse={() => handleConfirmPreparingSchedulingModal()}
        />
      )}
      {ordersMassActionsPopUpVisible && (
        <OrdersMassActionsPopUp
          ordersMassActionsPopUpVisible={ordersMassActionsPopUpVisible}
          setOrdersMassActionsPopUpVisible={() =>
            setOrdersMassActionsPopUpVisible()
          }
          currOrdersListFromSelectedTab={currOrdersList}
          cancelOrders={isMassOrderCanceling}
        />
      )}
    </>
  );
}
