import React from 'react';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import { getURL } from '../utils/utils';

import { store } from '../store';

import {
  getNewAcceptedOrdersWithoutLoaderRequest,
  getOrdersWithoutLoaderRequest,
} from '../store/modules/orders/actions';
import {
  updateWhatsappbotStatus,
  changeWhatsappbotFunctionsRequest,
  setWhatsappPlatform,
} from '../store/modules/whatsappBot/actions';
import { getOrdersMetricsRequest } from '../store/modules/dashboardMetrics/actions';
import {
  getSubscriptionsRequest,
  getRecurringRequest,
} from '../store/modules/profileAccount/actions';
import { getUserRequest } from '../store/modules/user/actions';
import {
  setIsConnected,
  addWaitAttendantList,
  clearChatState,
  removeWaitAttendantList,
  setSelectedConversation,
} from '../store/modules/whatsappChat/actions';
import { ddiPhoneMask } from '../utils/masks';

import history from '../services/history';
import { whatsappchat } from '../routes/routeMap';
import { forage } from '../store/persistReducers';
import {
  setIsHistorySyncComplete,
} from '../store/modules/whatsappChatConversations/actions';
import { setConversations } from '../store/modules/whatsappChatConversations/actions';
import { getChallengesStatusRequest } from '../store/modules/challenges/actions';
import { getMassMessageData } from '../store/modules/massMessage/actions';
import { WorkerSocket } from './WebSocket';

export function connectWS(atOnboarding = false) {
  const state = store.getState();
  const accessToken = state.auth.token;
  const advertiserInfoId = state.user.user.advertiserInfo.id;

  notificationWS.auth.token = accessToken;
  notificationWS.connect();

  notificationWS.on('connect', () => {
    console.debug(
      `WS - ${new Date()} - Socket ID: ${
        notificationWS.id
      } - connected to receive orders/whatsappbot/updated data status`
    );
    // Outside onboarding connect to receive new orders
    if (!atOnboarding) {
      notificationWS.emit('join-newOrders', {
        advertiserInfoId: advertiserInfoId,
      });
    }

    notificationWS.emit('join-whatsappbotStatus', {
      advertiserInfoId: advertiserInfoId,
    });

    notificationWS.emit('join-advertiserUpdatedData', {
      advertiserInfoId: advertiserInfoId,
    });

    notificationWS.emit('join-advertiserCallAttendant', {
      advertiserInfoId: advertiserInfoId,
    });

    notificationWS.emit('join-massMessagePaymentUpdated', {
      advertiserInfoId: advertiserInfoId,
    });
  });

  notificationWS.on('connect_error', (err) => {
    console.debug(
      `WS - ${new Date()} - Socket ID: ${
        notificationWS.id
      } - error connecting to receive orders/whatsappbot/updated data status, reason: ${err}`
    );
    if (!toast.isActive('notificationWSError')) {
      toast.error(
        'Ocorreu um erro na comunicação de busca de novos pedidos, verifique sua conexão de internet! Sem essa comunicação os novos pedidos não aparecerão automaticamente para você!',
        {
          autoClose: 8000,
          toastId: 'notificationWSError',
        }
      );
    }
  });

  notificationWS.on('message', ({ message }) => {
    console.debug(
      `WS - ${new Date()} - Socket ID: ${
        notificationWS.id
      } - message received: ${message}`
    );
    if (
      message.includes(
        `Socket ID: ${notificationWS.id} - successfully joined to "newOrders:${advertiserInfoId}"`
      )
    ) {
      toast.dismiss('notificationWSError');
    }
  });

  notificationWS.on('new_orders', (advertiserInfoId) => {
    console.debug(
      `WS - ${new Date()} - Socket ID: ${
        notificationWS.id
      } - new orders received: ${advertiserInfoId}`
    );
    // Retrieve the new orders without shown the loader on screen - update screen if in dashboard page
    store.dispatch(getOrdersWithoutLoaderRequest());
    // Retrieve the new/accepted orders without shown the loader on screen - update screen if in orders page
    store.dispatch(getNewAcceptedOrdersWithoutLoaderRequest());
    // Retrieve the orders metrics without loader
    store.dispatch(getOrdersMetricsRequest(false));
  });

  notificationWS.on(
    'advertiser_update_whatsappbot_status_event',
    (whatsappbotData) => {
      const state = store.getState();
      const isUsingChatbot = state.user.user.advertiserInfo.advertiserOrdersConfig.is_using_chatbot;
      const alwaysSendWhatsappMsg = state.user.user.advertiserInfo.advertiserOrdersConfig.is_send_whatsapp_msg_update_status;
      const whatsappbotDataChanged = JSON.parse(
        JSON.stringify(whatsappbotData)
      );
      whatsappbotDataChanged.qr_code_image = whatsappbotData.qr_code_image
        ? 'hasQRCode'
        : 'hasntQRCode';

      if (whatsappbotDataChanged.qr_code_image === 'hasQRCode') {
        forage.clear();
      }

      console.debug(
        `WS - ${new Date()} - Socket ID: ${
          notificationWS.id
        } - whatsappbot status received: ${JSON.stringify(
          whatsappbotDataChanged
        )}`
      );
      // Updated whatsapp bot data
      store.dispatch(
        updateWhatsappbotStatus(
          whatsappbotData.status,
          whatsappbotData.is_active,
          whatsappbotData.phone,
          whatsappbotData.qr_code_image
        )
      );
      store.dispatch(changeWhatsappbotFunctionsRequest(alwaysSendWhatsappMsg, isUsingChatbot));
      // If connected
      if (whatsappbotData.status === 'connected') {
        if (WorkerSocket.connected) {
          store.dispatch(setWhatsappPlatform(whatsappbotData.platform));
        }
      }
      // If disconnected
      if (whatsappbotData.status === 'disconnected') {
        store.dispatch(setConversations([]));
        store.dispatch(setIsConnected(false));
        store.dispatch(clearChatState());
        store.dispatch(setIsHistorySyncComplete(false));
        WorkerSocket.disconnect();
      }
    }
  );

  notificationWS.on(
    'advertiser_updated_data_event',
    (advertiserUpdatedData) => {
      console.debug(
        `WS - ${new Date()} - Socket ID: ${
          notificationWS.id
        } - updated advertiser data received: ${JSON.stringify(
          advertiserUpdatedData
        )}`
      );
      if (advertiserUpdatedData.updated_data_type === 'advertiser_infos') {
        console.debug('WS - user dispatch');
        // Retrieve the user data, even the user has been blocked, etc
        store.dispatch(getUserRequest());
      } else if (
        advertiserUpdatedData.updated_data_type === 'advertiser_subscriptions'
      ) {
        console.debug('WS - subscriptions dispatch');
        // Retrieve subscriptions to remove trial or delay payment bar
        store.dispatch(getSubscriptionsRequest());
        store.dispatch(getRecurringRequest());
      }
    }
  );

  notificationWS.on('advertiser_call_attendant_event', ({ client_phone }) => {
    const state = store.getState();
    const whatsappIsActive =
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active;

    console.debug(`
      WS - ${new Date()} - Socket ID: ${
      notificationWS.id
    } - client (${client_phone}) waiting to talk with advertiser received.
    `);

    if (!toast.isActive(`callAttenddant-${client_phone}`)) {
      const formatedPhone = ddiPhoneMask(client_phone);
      store.dispatch(addWaitAttendantList(client_phone));

      toast.warning(
        `Seu cliente com o número ${formatedPhone} solicitou atendimento humano no Whatsapp`,
        {
          onClick: () => {
            if ( whatsappIsActive) {
              store.dispatch(
                setSelectedConversation({
                  id: client_phone,
                  name: '',
                })
              );
              history.push(whatsappchat);
            } else {
              window.open(`https://wa.me/${client_phone}`);
            }

            store.dispatch(removeWaitAttendantList(client_phone));
          },
          toastId: `callAttenddant-${client_phone}`,
          autoClose: false,
        }
      );
    }
  });

  notificationWS.on('advertiser_update_mass_message_payment_event', (advertiserInfoId) => {
    const challengeState = store.getState().challenges.challenges
    const user = store.getState().user
    console.debug(
      `WS - ${new Date()} - Socket ID: ${
        notificationWS.id
      } - new update mass messages campaigns payments received: ${advertiserInfoId}`
    );
    // update mass messages when upated payment status
    store.dispatch(getMassMessageData());
    if (user != null && !challengeState?.is_completed_modal_closed) {
      store.dispatch(getChallengesStatusRequest());
    }
  });
}

export const notificationWS = io(getURL('ws'), {
  secure: false,
  auth: {
    token: '',
  },
  reconnection: true,
  rejectUnauthorized: false,
  transports: ['websocket'],
  path: '/lambda/socket.io',
  autoConnect: false,
});

export const SocketContext = React.createContext();
