export function loginRequest(email, password, isMobile) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: { email, password, isMobile },
  };
}

export function loginSuccess(token, user, pwdEncrypted) {
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { token, user, pwdEncrypted },
  };
}

export function loginFailure() {
  return {
    type: '@auth/LOGIN_FAILURE',
  };
}

export function logout(invalidSession = false) {
  return {
    type: '@auth/LOG_OUT_REQUEST',
    payload: { invalidSession }
  };
}

export function logoutSuccessFailure() {
  return {
    type: '@auth/LOG_OUT',
  };
}

export function recoverPasswordRequest(email) {
  return {
    type: '@auth/RECOVER_PASSWORD_REQUEST',
    payload: { email },
  };
}

export function recoverEmailRequest(name, cnpj) {
  return {
    type: '@auth/RECOVER_EMAIL_REQUEST',
    payload: { name, cnpj },
  };
}

export function recoverEmailSuccess(email) {
  return {
    type: '@auth/RECOVER_EMAIL_SUCCESS',
    payload: { email },
  };
}

export function recoverEmailFailure() {
  return {
    type: '@auth/RECOVER_EMAIL_FAILURE',
  };
}

export function adminAccessRequest(password) {
  return {
    type: '@auth/ADMIN_ACCESS_REQUEST',
    payload: { password },
  };
}

export function adminAccessSuccess() {
  return {
    type: '@auth/ADMIN_ACCESS_SUCCESS',
  };
}

export function adminAccessFailure() {
  return {
    type: '@auth/ADMIN_ACCESS_FAILURE',
  };
}

export function verifyResetAdminPasswordTokenRequest(token) {
  return {
    type: '@auth/VERIFY_RESET_ADMIN_PASSWORD_TOKEN_REQUEST',
    payload: { token },
  };
}

export function verifyResetAdminPasswordTokenSuccess() {
  return {
    type: '@auth/VERIFY_RESET_ADMIN_PASSWORD_TOKEN_SUCCESS',
  };
}

export function verifyResetAdminPasswordTokenFailure() {
  return {
    type: '@auth/VERIFY_RESET_ADMIN_PASSWORD_TOKEN_FAILURE',
  };
}

export function changeAdminPasswordRequest(token, newPassword) {
  return {
    type: '@auth/CHANGE_ADMIN_PASSWORD_REQUEST',
    payload: { token, newPassword },
  };
}

export function changeAdminPasswordSuccess() {
  return {
    type: '@auth/CHANGE_ADMIN_PASSWORD_SUCCESS',
  };
}

export function changeAdminPasswordFailure() {
  return {
    type: '@auth/CHANGE_ADMIN_PASSWORD_FAILURE',
  };
}

export function clearFields() {
  return {
    type: '@auth/CLEAR_FIELDS',
  };
}
