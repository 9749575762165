import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Icon } from 'react-materialize';
import moment from 'moment';

import { Row, Col } from '../../../../styles/grid';
import px2vw from '../../../../utils/responsiveness/px2vw';
import { formatCurrencyBRL } from '../../../../utils/utils';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ErrorText,
} from './styles';

import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TablePerPage from '../../../../components/TablePerPage';

export default function TopSoldProductsTable({ data }) {
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  function formatDateTimeLastOrder(lastOrderDateTime) {
    const lastOrderDate = moment(
      lastOrderDateTime,
      'DD/MM/YYYY HH:mm:ss',
      true
    ).format('DD/MM/YYYY');
    const currentDate = moment(new Date()).format('DD/MM/YYYY');

    if (lastOrderDate === currentDate) {
      return `Hoje às ${moment(
        lastOrderDateTime,
        'DD/MM/YYYY HH:mm:ss',
        true
      ).format('HH:mm')}`;
    }

    return moment(lastOrderDateTime, 'DD/MM/YYYY HH:mm:ss', true).format(
      'DD/MM/YYYY à\\s HH:mm'
    );
  }

  return (
    <Col padding={`0 ${px2vw(15)}`} alignCenter justifyCenter>
      <Row spaceBetween>
        <Header onClick={() => handleSort('product_name')}>
          <HeaderButton>
            Nome do Produto
            <SvgBox sorted={sortedCol === 'product_name'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('amount_of_sales')}>
          <HeaderButton>
            Qtd. de vendas
            <SvgBox sorted={sortedCol === 'amount_of_sales'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('average_ticket')}>
          <HeaderButton>
            Ticket médio
            <SvgBox sorted={sortedCol === 'average_ticket'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('total_billed')}>
          <HeaderButton>
            Total Faturado
            <SvgBox sorted={sortedCol === 'total_billed'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((product, index) =>
          structure[currentPage] &&
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 ? (
            <Fragment key={`client-${index}`}>
              <TableRow alignCenter justifyCenter>
                <Content>
                  {product.name === null ? 'Não informado' : product.name}
                </Content>
                <Content>
                  {product.amount_of_sales === null
                    ? 'Não informado'
                    : product.amount_of_sales}
                </Content>
                <Content>{formatCurrencyBRL(product.average_ticket, 2)}</Content>
                <Content>{formatCurrencyBRL(product.total_billed, 2)}</Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Col height="20vh">
          <Row alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>Você não tem produtos a serem exibidos.</ErrorText>
          </Row>
        </Col>
      )}

      {structure && (
        <Row alignCenter justifyEnd margtop={px2vw(10)}>
          <Row alignCenter justifyEnd height={px2vw(40)} margin={px2vw(10)}>
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
