import { printOrderInvoice } from '../printOrderInvoice/PrintOrderInvoice';
import { store } from '../../store'
import {
  sendWhatsappbotOrderStatusMessageRequest,
} from '../../store/modules/whatsappBot/actions';
import { formatCurrencyBRL } from '../utils';
import { printInvoiceQZTray } from '../QzPrint';

function getWhatsappMessageText(currOrderStatusData, pixKey = "Não informado", urlEncoded = false, resendPixKey = false) {
  let whatsappMessageText = '';
  if (currOrderStatusData) {
    // Client name for each type of message
    const clientName = currOrderStatusData.order.clientInfo.name.split(' ')[0];
    const clientNameBoldText = clientName ? ` *${clientName}*` : '';
    const clientNamePreparingMsgsText = clientName ? `, ${clientName}` : '';

    // Text when accept new immediate order or start preparing a scheduled order
    const startedPreparingOrderText =
      currOrderStatusData.order.delivery_type === 'delivery'
        ? 'sair para entrega'
        : 'ficar pronto para retirada';

    // User request to resend the chave pix
    if (resendPixKey) {
      whatsappMessageText = `Opa${clientNameBoldText}!

Segue abaixo o valor e a nossa Chave PIX para realizar o pagamento do pedido nº ${currOrderStatusData.order_id}:

Valor: *${formatCurrencyBRL(currOrderStatusData.order.total_amount, 2)}*
Chave PIX: *${pixKey}*

Por gentileza, assim que pagar, nos envie o comprovante por aqui.`;
    // Order with new status - type: immediate and scheduled or payment method: pix_chave
    } else if (currOrderStatusData.status === 'new') {
      if (currOrderStatusData.order.order_type === 'immediate') {
        if (currOrderStatusData.order.paymentMethod.slug === 'pix_chave') {
          // Message when accept a new immediate order with pix_chave payment method
          whatsappMessageText = `Opa${clientNameBoldText}!

Obrigado por fazer o seu pedido com a gente!

Nós acabamos de *aceitar o seu pedido* nº ${currOrderStatusData.order_id} e ele já está sendo preparado! Segue abaixo o valor e a nossa Chave PIX para realizar o pagamento:

Valor: *${formatCurrencyBRL(currOrderStatusData.order.total_amount, 2)}*
Chave PIX: *${pixKey}*

Por gentileza, assim que pagar, nos envie o comprovante por aqui.`;
        } else {
          // Message when accept a new immediate order
          whatsappMessageText = `Olá${clientNameBoldText}!

Obrigado por fazer o seu pedido com a gente!

Nós acabamos de *aceitar o seu pedido* nº ${currOrderStatusData.order_id} e ele já está sendo preparado! Te avisaremos assim que ele ${startedPreparingOrderText}.`;
        }
      } else if (currOrderStatusData.order.order_type === 'scheduled') {
        if (currOrderStatusData.order.paymentMethod.slug === 'pix_chave') {
          // Message when accept a new scheduled order with pix_chave payment method
          whatsappMessageText = `Opa${clientNameBoldText}!

Obrigado por fazer o seu pedido com a gente!

Nós acabamos de *aceitar o seu pedido* nº ${currOrderStatusData.order_id} e ele está agendado para ${currOrderStatusData.order.scheduling_start_at}! Te avisaremos assim que começarmos a prepará-lo.

Segue abaixo o valor e a nossa Chave PIX para realizar o pagamento:

Valor: *${formatCurrencyBRL(currOrderStatusData.order.total_amount, 2)}*
Chave PIX: *${pixKey}*

Por gentileza, assim que pagar, nos envie o comprovante por aqui.`;
        } else {
          // Message when accept a new scheduled order
          whatsappMessageText = `Olá${clientNameBoldText}!

Obrigado por fazer o seu pedido com a gente!

Nós acabamos de *aceitar o seu pedido* nº ${currOrderStatusData.order_id} e ele está agendado para ${currOrderStatusData.order.scheduling_start_at}! Te avisaremos assim que começarmos a prepará-lo.`;
        }
      }
    // Order with accepted status - scheduled
    } else if (currOrderStatusData.status === 'accepted') {
      if (currOrderStatusData.order.order_type === 'scheduled') {
        // Message when start preparing a scheduled order
        whatsappMessageText = `Olá${clientNameBoldText}!

Nós acabamos de *iniciar o preparo do seu pedido* nº ${
          currOrderStatusData.order_id
        }! Te avisaremos assim que ele ${startedPreparingOrderText}.`;
      }
    // Order with preparing status - immediate and scheduled
    } else if (currOrderStatusData.status === 'preparing') {
      // Text when finished the immediate order preparing
      const finishedPreparingImmediateOrderText1 =
        currOrderStatusData.order.delivery_type === 'delivery'
          ? 'acabou de sair para entrega'
          : 'está pronto para você retirar';
      const finishedPreparingImmediateOrderText2 =
        currOrderStatusData.order.delivery_type === 'delivery'
          ? 'Em alguns minutos deve estar chegando aí no seu endereço! Preparamos com todo carinho, esperamos que você goste!'
          : 'Estamos te aguardando aqui!';

      // Message when finished the order preparing
      whatsappMessageText = `Oba!!

Seu pedido nº ${currOrderStatusData.order_id} *${finishedPreparingImmediateOrderText1}*${clientNamePreparingMsgsText}!

${finishedPreparingImmediateOrderText2}`;
    } else if (currOrderStatusData.status === 'canceled') {
      // Message when the order canceled
      whatsappMessageText = `Olá, ${clientName}!

Infelizmente tivemos que *cancelar o seu pedido* nº ${currOrderStatusData.order_id}.

Caso tenha alguma dúvida, estamos disponíveis para lhe atender!`;
    }
  }

  // Return the whatsapp message encoded to be used at the URL
  if (urlEncoded) {
    return encodeURI(whatsappMessageText);
  }
  // Normal message to be sent using the whatsappbot
  else {
    return whatsappMessageText
  }
}

export function printAutomaticallyOrderInvoice(
  selectedPrintOrder,
  currOrderStatusData,
  advertiserUser,
  printMethod,
  printerName,
  maxLineCharsAutomatic,
  isNewManualOrder
) {
  if (
    selectedPrintOrder &&
    currOrderStatusData &&
    (((currOrderStatusData.status === 'new' ||
    (currOrderStatusData.order.is_created_manually && currOrderStatusData.status === 'preparing' && isNewManualOrder)) &&
      currOrderStatusData.order.order_type === 'immediate') ||
      currOrderStatusData.status === 'accepted')
  ) {
    if (printMethod === 'automatic') {
      printInvoiceQZTray({
        printerName: printerName,
        orderStatusData: currOrderStatusData,
        advertiserData: advertiserUser,
        maxLineCharsAutomatic: maxLineCharsAutomatic
      })
    } else {
      printOrderInvoice({
        orderStatusData: currOrderStatusData,
        advertiserData: advertiserUser,
        showErrorMessage: true,
      });
    }
  }
}

export function sendWhatsappMessage(
  selectedSendWhatsappMessage,
  currOrderStatusData,
  whatsappIsActive,
  pixKey,
  resendPixKey = false
) {
  if (
    selectedSendWhatsappMessage &&
    currOrderStatusData &&
    whatsappIsActive && 
    currOrderStatusData.order.clientInfo.phone && 
    (['new', 'accepted', 'preparing', 'canceled'].includes(currOrderStatusData.status) || resendPixKey)
  ) {
    store.dispatch(sendWhatsappbotOrderStatusMessageRequest(
      currOrderStatusData.order.clientInfo.phone,
      getWhatsappMessageText(currOrderStatusData, pixKey, false, resendPixKey)
    ))
  }
}