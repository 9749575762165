import React, { useEffect, useMemo, useState } from 'react';
import { verdeKuppi14 } from '../../../../styles/colors';
import { Col, Row } from '../../../../styles/grid';
import { AiOutlinePlus } from 'react-icons/ai';
import px2vw from '../../../../utils/responsiveness/px2vw';
import {
  Bagde,
  ManualOrderButtonPrimary,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
  Span,
  WindowHeader,
} from './style';
import {
  setIsOpennedFromWhatsappchat,
  setManualOrderModalState,
  verifyUserExistsByPhoneRequest,
} from '../../../../store/modules/manualOrder/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearSearchedOrderData,
  getClientOrdersData,
} from '../../../../store/modules/orders/actions';
import { useCallback } from 'react';
import OrderDetailModal from '../../../../components/OrderDetailModal';
import WppAvatar from '../../../../components/WppAvatar';
import { ddiPhoneMask } from '../../../../utils/masks';
import { getClientName } from '../../../../utils/getClientName';
import { formatCurrencyBRL } from '../../../../utils/utils';

export default function ChatHeader() {
  const dispatch = useDispatch();
  const [orderDetailModal, setOrderDetailModal] = useState(false);
  const { allClientsData, selectedConversation } =
    useSelector((state) => state.whatsappChat);
  const { conversations } = useSelector(
    (state) => state.whatsappChatConversations
  );
  const { currSearchedOrderData } = useSelector((state) => state.orders);

  const currClientData = useMemo(() => {
    return allClientsData.find(
      (client) => client.phone === selectedConversation.id
    );
  }, [allClientsData, selectedConversation]);

  const pushname = useMemo(() => {
    const exibitionName = getClientName(
      selectedConversation.id,
      conversations[selectedConversation.id] || []
    );
    return exibitionName?.length > 22
      ? exibitionName.slice(0, 25).replace('\\n', ' ') + '...'
      : exibitionName;
  }, [selectedConversation, allClientsData, conversations]);

  const [orderTag, setOrderTag] = useState(null);

  const getOrderId = useCallback(() => {
    if (!currClientData || !currClientData.active_orders.length) {
      return false;
    }

    return currClientData.active_orders.map((item) => item.order_id);
  }, [currClientData]);

  const getClientTag = () => {
    if (!currClientData) {
      return 'Potencial Cliente';
    }

    const { client_type_tag } = currClientData;

    return client_type_tag || 'Cliente novo';
  };

  const getOrderStatusTag = () => {
    if (!currClientData || !currClientData.active_orders.length) {
      return false;
    }

    return currClientData.active_orders[0].status;
  };

  const handleGetOrderDetails = () => {
    const orderIds = getOrderId(selectedConversation.id);
    if (orderIds) {
      dispatch(getClientOrdersData(orderIds));
      setOrderDetailModal(true);
    }
  };

  const handleOpenManualOrderModal = () => {
    dispatch(setManualOrderModalState(true));
    dispatch(setIsOpennedFromWhatsappchat());
    dispatch(verifyUserExistsByPhoneRequest(selectedConversation?.id));
  };

  const handleCloseOrderDetails = () => {
    dispatch(clearSearchedOrderData());
    setOrderDetailModal(false);
  };

  useEffect(() => {
    setOrderTag(getOrderStatusTag(selectedConversation?.id));
  }, [selectedConversation, allClientsData]);

  return (
    <WindowHeader>
      <WppAvatar fullName={pushname?.split(' ')} width="auto" />
      <Col
        height="100%"
        padtop={px2vw(8)}
        padbottom={px2vw(8)}
        padleft={px2vw(10)}
        padright={px2vw(8)}
        width={px2vw(235)}
      >
        <Row width={px2vw(195)} justifyStart height="auto">
          <Span
            letterSpacing="0.005em"
            fontWeight="500"
            size={px2vw(15)}
            whiteSpace="nowrap"
            mr={px2vw(6)}
          >
            {(pushname.match(/\+\d+/gm) ? '-' : pushname) || '-'}
          </Span>
        </Row>
        <Span
          letterSpacing="0.005em"
          fontWeight="500"
          size={px2vw(15)}
          textAlign="initial"
          maxWidth={px2vw(200)}
          whiteSpace="nowrap"
        >
          {ddiPhoneMask(selectedConversation.id) || '-'}
        </Span>
        <Row spaceAround height="auto" width="auto">
          <Bagde h={px2vw(17)} mr={px2vw(16)}>
            {getClientTag()}
          </Bagde>
          {orderTag ? (
            <Bagde h={px2vw(17)} bg={verdeKuppi14}>
              {orderTag}
            </Bagde>
          ) : (
            <div style={{ minWidth: px2vw(70) }}></div>
          )}
        </Row>
      </Col>
      <Col
        spaceAround
        padleft={px2vw(8)}
        padtop={px2vw(8)}
        padbottom={px2vw(8)}
        padright={px2vw(8)}
        width={px2vw(200)}
      >
        <Row justifyStart height="auto">
          <Span
            letterSpacing="0.005em"
            fontWeight="500"
            size={px2vw(14)}
            whiteSpace="nowrap"
            mr={px2vw(6)}
          >
            Total de pedidos:{' '}
            <Span
              letterSpacing="0.005em"
              fontWeight="600"
              size={px2vw(12)}
              textAlign="initial"
              maxWidth={px2vw(200)}
              whiteSpace="nowrap"
            >
              {currClientData?.total_orders}
            </Span>
          </Span>
        </Row>
        <Span
          letterSpacing="0.005em"
          fontWeight="500"
          size={px2vw(14)}
          textAlign="initial"
          maxWidth={px2vw(200)}
          whiteSpace="nowrap"
        >
          Total de receita:{' '}
          <Span
            letterSpacing="0.005em"
            fontWeight="600"
            size={px2vw(12)}
            textAlign="initial"
            maxWidth={px2vw(200)}
            whiteSpace="nowrap"
          >
            {currClientData?.revenue
              ? formatCurrencyBRL(currClientData?.revenue?.toFixed(2), {})
              : 'R$ 0,00'}
          </Span>
        </Span>
        <Row justifyStart height="auto">
          <Span
            letterSpacing="0.005em"
            fontWeight="500"
            size={px2vw(14)}
            mr={px2vw(6)}
            textAlign="initial"
            maxWidth={px2vw(200)}
            whiteSpace="nowrap"
          >
            Último pedido:{' '}
            <Span
              letterSpacing="0.005em"
              fontWeight="600"
              size={px2vw(12)}
              textAlign="initial"
              maxWidth={px2vw(200)}
              whiteSpace="nowrap"
            >
              {currClientData?.last_order || '-'}
            </Span>
          </Span>
        </Row>
      </Col>
      <Col
        spaceBetween
        alignCenter
        height="100%"
        padtop={px2vw(1)}
        padbottom={px2vw(1)}
        padleft={px2vw(10)}
        padright={px2vw(8)}
        width={px2vw(200)}
      >
        <ModalSecundaryButton
          onClick={() => handleGetOrderDetails()}
          width={px2vw(180)}
          disabled={!getOrderId(selectedConversation.id)}
        >
          <ModalSecundaryButtonText
            disabled={!getOrderId(selectedConversation.id)}
          >
            Detalhes do pedido
          </ModalSecundaryButtonText>
        </ModalSecundaryButton>
        <ManualOrderButtonPrimary onClick={() => handleOpenManualOrderModal()}>
          <Row justifyStart width="auto">
            <AiOutlinePlus
              className="my-auto"
              fontSize={px2vw(14)}
              fontWeight="bold"
            />
            <Row
              width="auto"
              style={{ fontSize: px2vw(12) }}
              margleft={px2vw(9)}
            >
              Criar pedido manual
            </Row>
          </Row>
        </ManualOrderButtonPrimary>
      </Col>

      {/* Order detail modal */}
      {orderDetailModal && (
        <OrderDetailModal
          isVisible={orderDetailModal}
          orders={currSearchedOrderData || []}
          hasNavigation={currSearchedOrderData?.length > 0}
          orderIndex={0}
          onModalResponse={handleCloseOrderDetails}
          onScreen="whatsappChat"
          orderStatusRequest={'tabNew'}
        />
      )}
    </WindowHeader>
  );
}
