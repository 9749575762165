import styled from 'styled-components';

import px2vw from '../../../utils/responsiveness/px2vw';

import {
  amareloKuppi3,
  amareloKuppi4,
  verdeKuppi7,
  verdeKuppi8,
  verdeKuppi4,
} from '../../../styles/colors';

export const ClockImage = styled.img`
  height: ${px2vw(13)};
  width: ${px2vw(13)};
  margin-right: ${px2vw(10)};

  ${(props) => `
    height: ${props.size};
    width: ${props.size};
    margin-top: ${props.margtop};
    margin-right: ${props.margright};
  `};
`;

export const OrdersRowText = styled.span`
  font-size: ${px2vw(14)};
  font-family: AvenirNextLTPro;
  font-weight: normal;
  letter-spacing: 0.36px;

  ${(props) =>
    props.isBold &&
    `
    font-family: AvenirNextLTPro;
    font-weight: 700;
  `};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
    font-size: ${props.fontSize};
    width: ${props.width};
  `};

   ${(props) =>
    props.clientName &&
    `
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 24ch;
  `};
`;

export const OrderTypeBox = styled.div`
  border: none;
  height: ${px2vw(30)};
  width: ${px2vw(70)};
  margin-top: ${px2vw(15)};
  margin-bottom: ${px2vw(5)};
  border-radius: ${px2vw(5)};
  padding: ${px2vw(7)};
  display: flex;
  justify-content: center;
  font-size: ${px2vw(11)};
  font-family: AvenirNextLTPro;
  font-weight: 700;
  letter-spacing: 0.36px;

  ${(props) =>
    props.orderType === 'take_away' &&
    `
      background-color: ${amareloKuppi3};
      color: ${amareloKuppi4};
    `};

  ${(props) =>
    props.orderType === 'delivery' &&
    `
      background-color: ${verdeKuppi7};
      color: ${verdeKuppi8};
    `};

  ${(props) => `
    margin-top: ${props.margtop};
    margin-bottom: ${props.margbottom};
    margin-left: ${props.margleft};
    margin-right: ${props.margright};
  `};
`;

export const SeeOrderBox = styled.div`
  padding: ${px2vw(5)};
  border: none;
  height: ${px2vw(25)};
  width: ${px2vw(75)};
  border-radius: ${px2vw(5)};
  background-color: ${verdeKuppi4}60;
  display: flex;
  justify-content: center;

  ${(props) => `
    margin-left: ${props.margleft};
    width: ${props.width};
    height: ${props.height};
    padding-top: ${props.padtop};
    margin-top: ${props.margtop};
  `};
`;
