export const login = '/acesso-portal';
export const firstSteps = '/primeiros-passos';
export const onboarding = '/onboarding';
export const termsOfUse = '/termos-de-uso';
export const resetAdminPassword = '/redefinicao-senha-administrador';
export const signaturePayment = '/pagamento-assinatura';
export const blockedSignaturePayment = '/portal/pagamento-assinatura-bloqueada';
export const askDownloadApp = '/portal/baixe-nosso-app';
export const dashboard = '/portal/dashboard';
export const whatsappbot = '/portal/whatsappbot';
export const profileAndSignature = '/portal/perfil-e-assinatura';
export const myStore = '/portal/minha-loja';
export const reports = '/portal/relatorios';
export const kuppiBoost = '/portal/kuppi-boost';
export const catalog = '/portal/catalogo';
export const orders = '/portal/pedidos';
export const clients = '/portal/clientes';
export const ratings = '/portal/avaliacoes';
export const manageCoupons = '/portal/gerenciar-cupons';
export const usedCoupons = '/portal/cupons-utilizados';
export const loyaltyCard = '/portal/cartao-fidelidade';
export const whatsappchat = '/portal/whatsappchat';
export const deliveryGuru = '/portal/guru-do-delivery';
export const salesRecover = '/portal/recuperador-de-vendas';
export const massMessage = '/portal/mensagem-em-massa';
export const financial = '/portal/financeiro';
