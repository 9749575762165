import produce from 'immer';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  recoverEmailLoading: false,
  recoverEmailSuccess: false,
  recoverEmailError: false,
  recoveredEmail: '',
  adminAccessLoading: false,
  adminAccessSuccess: false,
  adminAccessError: false,
  verifyResetAdminPasswordTokenLoading: false,
  verifyResetAdminPasswordTokenSuccess: false,
  changePasswordLoading: false,
  changePasswordSuccess: false,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@auth/LOGIN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@auth/LOGIN_SUCCESS': {
        draft.token = action.payload.token.token;
        draft.signed = true;
        draft.loading = false;
        break;
      }
      case '@auth/LOGIN_FAILURE': {
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        break;
      }
      case '@auth/LOG_OUT': {
        draft.token = null;
        draft.signed = false;
        draft.loading = false;
        break;
      }
      case '@auth/RECOVER_PASSWORD_REQUEST': {
        draft.recoverPasswordLoading = true;
        draft.recoverPasswordSuccess = false;
        break;
      }
      case '@auth/RECOVER_EMAIL_REQUEST': {
        draft.recoverEmailLoading = true;
        draft.recoverEmailSuccess = false;
        draft.recoverEmailError = false;
        draft.recoveredEmail = '';
        break;
      }
      case '@auth/RECOVER_EMAIL_SUCCESS': {
        draft.recoverEmailLoading = false;
        draft.recoverEmailSuccess = true;
        draft.recoverEmailError = false;
        draft.recoveredEmail = action.payload.email;
        break;
      }
      case '@auth/RECOVER_EMAIL_FAILURE': {
        draft.recoverEmailLoading = false;
        draft.recoverEmailSuccess = false;
        draft.recoverEmailError = true;
        draft.recoveredEmail = '';
        break;
      }
      case '@auth/ADMIN_ACCESS_REQUEST': {
        draft.adminAccessLoading = true;
        draft.adminAccessSuccess = false;
        draft.adminAccessError = false;
        break;
      }
      case '@auth/ADMIN_ACCESS_SUCCESS': {
        draft.adminAccessLoading = false;
        draft.adminAccessSuccess = true;
        draft.adminAccessError = false;
        break;
      }
      case '@auth/ADMIN_ACCESS_FAILURE': {
        draft.adminAccessLoading = false;
        draft.adminAccessSuccess = false;
        draft.adminAccessError = true;
        break;
      }
      case '@auth/VERIFY_RESET_ADMIN_PASSWORD_TOKEN_REQUEST': {
        draft.verifyResetAdminPasswordTokenLoading = true;
        draft.verifyResetAdminPasswordTokenSuccess = false;
        draft.changePasswordLoading = false;
        draft.changePasswordSuccess = false;
        break;
      }
      case '@auth/VERIFY_RESET_ADMIN_PASSWORD_TOKEN_SUCCESS': {
        draft.verifyResetAdminPasswordTokenLoading = false;
        draft.verifyResetAdminPasswordTokenSuccess = true;
        break;
      }
      case '@auth/VERIFY_RESET_ADMIN_PASSWORD_TOKEN_FAILURE': {
        draft.verifyResetAdminPasswordTokenLoading = false;
        draft.verifyResetAdminPasswordTokenSuccess = false;
        break;
      }
      case '@auth/CHANGE_ADMIN_PASSWORD_REQUEST': {
        draft.verifyResetAdminPasswordTokenLoading = false;
        draft.verifyResetAdminPasswordTokenSuccess = false;
        draft.changePasswordLoading = true;
        draft.changePasswordSuccess = false;
        break;
      }
      case '@auth/CHANGE_ADMIN_PASSWORD_SUCCESS': {
        draft.changePasswordLoading = false;
        draft.changePasswordSuccess = true;
        break;
      }
      case '@auth/CHANGE_ADMIN_PASSWORD_FAILURE': {
        draft.changePasswordLoading = false;
        draft.changePasswordSuccess = false;
        break;
      }
      case '@auth/CLEAR_FIELDS': {
        draft.recoverPasswordLoading = false;
        draft.recoverPasswordSuccess = false;
        draft.recoverEmailLoading = false;
        draft.recoverEmailSuccess = false;
        draft.recoverEmailError = false;
        draft.adminAccessLoading = false;
        draft.adminAccessSuccess = false;
        draft.adminAccessError = false;
        draft.verifyResetAdminPasswordTokenLoading = false;
        draft.changePasswordLoading = false;
        draft.changePasswordSuccess = false;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}
