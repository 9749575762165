import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col } from '../../styles/grid';
import { LoadingBox } from '../../pages/_layouts/styles/common';

import {
  ModalHeader,
  ModalTitleText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
  ModalPrimaryButton,
  ModalPrimaryButtonText,
} from '../Modal/styles';

import { preto } from '../../styles/colors';

import Modal from '../Modal';

import px2vw from '../../utils/responsiveness/px2vw';
import {
  SelectActionButtonBorder,
  SelectActionButtonGreen,
  TextForSelectButton,
} from './styles';
import { Spinner } from 'reactstrap';
import { updateMultipleOrdersStatusesRequest } from '../../store/modules/orders/actions';
import {
  printAutomaticallyOrderInvoice,
  sendWhatsappMessage,
} from '../../utils/ordersConfigs/ordersConfigs';

export default function OrdersMassActionsModal(props) {
  const {
    ordersMassActionsModalVisible,
    setOrdersMassActionsModalVisible,
    listWithAllIds,
    ordersListByCurrentTab,
  } = props;

  const [cancelOrders, setCancelOrders] = useState(false);
  const [advanceStep, setAdvanceStep] = useState(false);

  const listToMassActionLoading = useSelector(
    (state) => state.orders.listToMassActionLoading
  );

  const whatsappIsActive = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserWhatsappbotConfig.is_active
  );

  const alwaysSendWhatsappMsg = useSelector(
    (state) =>
      state.user.user.advertiserInfo.advertiserOrdersConfig
        .is_send_whatsapp_msg_update_status
  );

  const currOrderConfigs = useSelector(
    (state) => state.orders.currOrderConfigs
  );
  const user = useSelector((state) => state.user.user);
  const advertiserUser = useSelector((state) => state.user.user.advertiserInfo);
  const printMethod = advertiserUser.advertiserOrdersConfig.print_method;
  const printerName = advertiserUser.advertiserPrintConfig.printer_name;
  const maxLineCharsAutomatic =
    advertiserUser.advertiserPrintConfig.max_line_chars_automatic;

  const dispatch = useDispatch();

  const returnModalAction = useCallback(() => {
    function execute() {
      setOrdersMassActionsModalVisible(false);
    }

    execute();
  });

  function closeModal() {
    returnModalAction();
  }

  function handleSelectAction(action) {
    if (action === 'cancelOrders') {
      setCancelOrders(true);
      setAdvanceStep(false);
    } else if (action === 'advanceStep') {
      setCancelOrders(false);
      setAdvanceStep(true);
    }
  }

  function handleUpdateOrders() {
    if(!cancelOrders && !advanceStep) {
      toast.error('Selecione a ação desejada', {
        autoClose: 6000,
      });
      return
    }
    
    dispatch(
      updateMultipleOrdersStatusesRequest(listWithAllIds, cancelOrders)
    );

    if (alwaysSendWhatsappMsg && whatsappIsActive) {
      ordersListByCurrentTab.forEach((order) => {
        if (listWithAllIds.includes(order.order_id)) {
          let currOrder = order
          if(cancelOrders) {
            currOrder = {...order, status: "canceled"}
          }
          sendWhatsappMessage(
            alwaysSendWhatsappMsg,
            currOrder,
            whatsappIsActive,
            null
          );
        }
      });
    }

    if (printMethod === 'automatic') {
      ordersListByCurrentTab.forEach((order) => {
        if (listWithAllIds.includes(order.order_id)) {
          if (printMethod === 'automatic') {
            printAutomaticallyOrderInvoice(
              currOrderConfigs.printOrder,
              order,
              user,
              printMethod,
              printerName,
              maxLineCharsAutomatic,
              false
            );
          }
        }
      });
    }
  }

  return (
    <Modal
      isVisible={ordersMassActionsModalVisible}
      width="auto"
      height={px2vw(200)}
    >
      <ModalHeader>
        <ModalTitleText textalign="center">
          Qual ação em massa executar?
        </ModalTitleText>
      </ModalHeader>

      {/* Cancel Orders */}
      <Row
        margtop={px2vw(30)}
        alignCenter
        justifyStart
        onClick={() => handleSelectAction('cancelOrders')}
        cursor="pointer"
      >
        <Col margright={px2vw(10)} width="auto" height="auto">
          <SelectActionButtonBorder>
            <SelectActionButtonGreen isActive={cancelOrders} />
          </SelectActionButtonBorder>
        </Col>
        <TextForSelectButton>
          Cancelar os pedidos selecionados
        </TextForSelectButton>
      </Row>

      {/* Advance Step */}
      <Row
        margtop={px2vw(15)}
        alignCenter
        justifyStart
        onClick={() => handleSelectAction('advanceStep')}
        cursor="pointer"
      >
        <Col margright={px2vw(10)} width="auto" height="auto">
          <SelectActionButtonBorder>
            <SelectActionButtonGreen isActive={advanceStep} />
          </SelectActionButtonBorder>
        </Col>
        <TextForSelectButton>
          Avançar de etapa os pedidos selecionados
        </TextForSelectButton>
      </Row>
      <ModalActions>
        <ModalSecundaryButton
          marginRight={px2vw(15)}
          margtop={px2vw(25)}
          width={px2vw(145)}
          type="button"
          onClick={closeModal}
          disabled={listToMassActionLoading}
        >
          <ModalSecundaryButtonText>Cancelar</ModalSecundaryButtonText>
        </ModalSecundaryButton>
        <ModalPrimaryButton
          margleft={px2vw(15)}
          margtop={px2vw(25)}
          width={px2vw(145)}
          type="button"
          onClick={handleUpdateOrders}
          disabled={listToMassActionLoading}
        >
          {listToMassActionLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />
            </LoadingBox>
          ) : (
            <ModalPrimaryButtonText>Confirmar</ModalPrimaryButtonText>
          )}
        </ModalPrimaryButton>
      </ModalActions>
    </Modal>
  );
}
