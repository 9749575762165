import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Icon } from 'react-materialize';
import { Row, Col } from '../../styles/grid';
import px2vw from '../../utils/responsiveness/px2vw';

import {
  Header,
  Content,
  Hr,
  TableRow,
  HeaderButton,
  PagButton,
  PrevIcon,
  NextIcon,
  ErrorText,
} from './styles';

import { SvgBox, Arrow, Arrow2 } from './icons';
import { sort, makePages } from './tableOptions';

import TablePerPage from '../TablePerPage';

import { amountMask, dddPhoneMask } from '../../utils/masks';

export default function LoyaltyCardHistoryTable({ data }) {
  const [rows, setRows] = useState([]);
  const [rowsCurrentPage, setRowsCurrentPage] = useState();
  const [structure, setStructure] = useState(null);
  const [currentPage, setCurrentPage] = useState('1');
  const [lastPage, setLastPage] = useState();
  const [firstPage] = useState('1');
  const [sortedCol, setSortedCol] = useState('');

  useEffect(() => {
    if (structure) {
      setLastPage(
        Object.keys(structure)[Object.keys(structure).length - 1].toString()
      );
    }
  }, [structure]);

  useMemo(() => {
    setStructure(makePages(rows.length, rowsCurrentPage));
  }, [rows, rowsCurrentPage]);

  useEffect(() => {
    setRows(data);
  }, [data]);

  useEffect(() => {
    if (rows.length < 5) {
      setRowsCurrentPage(rows.length);
    }
    if (rowsCurrentPage > 5) {
    } else {
      setRowsCurrentPage(5);
    }
  }, [rows]);

  const handleSort = useCallback(
    (col) => {
      if (sortedCol !== col) {
        setSortedCol(col);
        setRows(sort(rows, col, 'asc'));
      } else {
        setSortedCol('');
        setRows(sort(rows, col, 'desc'));
      }
    },
    [rows]
  );

  function handlePerPage(size) {
    setRowsCurrentPage(size);
  }

  function handleChangePage(direction) {
    if (direction === 'next') {
      const curr = +currentPage + 1;
      setCurrentPage(curr.toString());
    } else if (direction === 'prev') {
      const curr = +currentPage - 1;
      setCurrentPage(curr.toString());
    }
  }

  return (
    <Col padding="0 15px" alignCenter justifyCenter>
      <Row>
        <Header onClick={() => handleSort('name')}>
          <HeaderButton>
            Cliente
            <SvgBox sorted={sortedCol === 'name'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('phone')}>
          <HeaderButton>
            Telefone
            <SvgBox sorted={sortedCol === 'phone'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>
        
        <Header onClick={() => handleSort('type')}>
          <HeaderButton>
            Prêmio
            <SvgBox sorted={sortedCol === 'type'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('total_purchases')}>
          <HeaderButton>
            Número de compras
            <SvgBox sorted={sortedCol === 'total_purchases'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('local')}>
          <HeaderButton>
            Local da última compra
            <SvgBox sorted={sortedCol === 'local'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>

        <Header onClick={() => handleSort('last_order_date_time')}>
          <HeaderButton>
            Data e hora da última compra
            <SvgBox sorted={sortedCol === 'last_order_date_time'}>
              <Arrow />
              <Arrow2 />
            </SvgBox>
          </HeaderButton>
        </Header>
      </Row>
      <Row>
        <Hr />
      </Row>
      {structure ? (
        rows.map((item, index) =>
          structure[currentPage] &&
          structure[currentPage].start <= index + 1 &&
          structure[currentPage].end >= index + 1 ? (
            <Fragment key={`loyaltyCardHistory-${index}`}>
              <TableRow alignCenter justifyCenter>
                <Content>{item.name === null ? item.email : item.name}</Content>
                <Content>{dddPhoneMask(item.phone)}</Content>
                <Content>
                  {item.type === 'percentage_cart' &&
                    `${item.cart_discount_percentage}% de desconto`}
                  {item.type === 'fixed_cart' &&
                    `${amountMask(item.cart_discount_fixed.toFixed(2))}`}
                </Content>
                <Content>{item.total_purchases}</Content>
                <Content>{item.last_order_local}</Content>
                <Content>{item.last_order_date_time}</Content>
              </TableRow>
              <Row>
                <Hr />
              </Row>
            </Fragment>
          ) : null
        )
      ) : (
        <Col height="20vh">
          <Row alignCenter justifyCenter>
            {/* TODO: Ajustar tamanho do icone */}
            <Icon large>sentiment_very_dissatisfied</Icon>
            <ErrorText>
              Você ainda não tem histórico de compras no seu cartão fidelidade.
            </ErrorText>
          </Row>
        </Col>
      )}
      {structure && (
        <Row alignCenter justifyEnd>
          <Row alignCenter justifyEnd height={px2vw(40)} margin="10px">
            <TablePerPage response={handlePerPage} length={rows.length} />
            <Row alignCenter justifyCenter width="10%">
              {!structure[currentPage] && handleChangePage('prev')}
              {structure[currentPage] && structure[currentPage].start} -{' '}
              {structure[currentPage] && structure[currentPage].end} de{' '}
              {rows.length}
            </Row>
            <PagButton
              onClick={() => handleChangePage('prev')}
              disabled={currentPage === firstPage}
              title="Página anterior"
            >
              <PrevIcon disabled={currentPage === firstPage} />
            </PagButton>
            <PagButton
              onClick={() => handleChangePage('next')}
              title="Próxima página"
              disabled={currentPage === lastPage}
            >
              <NextIcon disabled={currentPage === lastPage} />
            </PagButton>
          </Row>
        </Row>
      )}
    </Col>
  );
}
