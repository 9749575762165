import React from 'react';
import { FormControlLabel } from '@material-ui/core/';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';

import {
  preto,
} from '../../../../styles/colors';

import {
  ButtonImage,
  TabContent,
  ProductInputLabel,
  ProductInput,
  ProductInputLabelRegular,
  DisponibilityLabel,
  ProdutCouponBox,
  CouponOptionBox,
} from '../../styles';

import {
  SwitchController,
  SwitchButtonBox,
  LoadingBox,
} from '../../../_layouts/styles/common';

import { Row, Col } from '../../../../styles/grid';

import {
  ModalPrimaryButton,
  ModalPrimaryButtonText,
  ModalActions,
  ModalSecundaryButton,
  ModalSecundaryButtonText,
} from '../../../../components/Modal/styles';

import px2vw from '../../../../utils/responsiveness/px2vw';
import {
  amountMask,
  screenAmountMask,
  timeMask,
  dateMask,
} from '../../../../utils/masks';
import { onlyNumbers, onlyNumbersAmount } from '../../../../utils/utils';

export default function TabCoupons({ handler }) {
  // Clear promotional amout value when enable or disable active discount coupon
  function handleActiveCoupon() {
    handler.setActiveCoupon(!handler.activeCoupon);
    if (handler.flashCoupon) {
      handleLightningCoupon();
    }
  }

  // Clear fields when enable or disable lightning coupon
  function handleLightningCoupon() {
    if (handler.activeCoupon) {
      handler.setFlashCoupon(!handler.flashCoupon);
    } else {
      toast.error(
        'Não é possível ativar o cupom relâmpago sem que o cupom de desconto esteja ativo.',
        {
          autoClose: 6000,
        }
      );
    }
  }

  return (
    <TabContent>
      <Col id="productCoupon">
        <DisponibilityLabel>
          Deseja criar um cupom de desconto para o produto?
        </DisponibilityLabel>
        <ProdutCouponBox>
          <Row>
            <Col width="auto">
              <Col margleft={px2vw(20)} margtop={px2vw(-5)}>
                <SwitchButtonBox width={px2vw(250)}>
                  <FormControlLabel
                    control={
                      <SwitchController
                        checked={handler.activeCoupon}
                        type="checkbox"
                        onChange={() => {
                          if (
                            parseFloat(
                              onlyNumbersAmount(
                                handler.productAmountInputValue
                              )
                            ) === parseFloat(0)
                          ) {
                            toast.error(
                              'Não é possível ativar um cupom de desconto para produto com valor zero.',
                              {
                                autoClose: 6000,
                              }
                            );
                          } else if (
                            !handler.productImageURL &&
                            !handler.fileProductData.base64
                          ) {
                            toast.error(
                              'Não é possível ativar um cupom de desconto para produto sem foto.',
                              {
                                autoClose: 6000,
                              }
                            );
                          } else {
                            handleActiveCoupon();
                          }
                        }}
                      />
                    }
                    label={
                      <ProductInputLabel>
                        Ativar cupom de desconto
                      </ProductInputLabel>
                    }
                  />
                </SwitchButtonBox>
                <ProductInputLabelRegular
                  margleft={px2vw(10)}
                  width={px2vw(220)}
                >
                  Use o botão para ativar ou desativar a promoção
                </ProductInputLabelRegular>
              </Col>
              <Col margleft={px2vw(20)} margtop={px2vw(-30)}>
                <SwitchButtonBox width={px2vw(250)}>
                  <FormControlLabel
                    control={
                      <SwitchController
                        checked={handler.flashCoupon}
                        type="checkbox"
                        onChange={handleLightningCoupon}
                      />
                    }
                    label={
                      <ProductInputLabel>
                        Cupom relâmpago
                      </ProductInputLabel>
                    }
                  />
                </SwitchButtonBox>
                <ProductInputLabelRegular
                  margleft={px2vw(10)}
                  width={px2vw(220)}
                >
                  Cupom relâmpago é uma oferta limitada com prazo
                  para acabar
                </ProductInputLabelRegular>
              </Col>
            </Col>
            <Col height="auto" margleft={px2vw(50)}>
              <CouponOptionBox margtop={px2vw(40)}>
                <ProductInputLabelRegular margright={px2vw(78)}>
                  Valor Original:
                </ProductInputLabelRegular>
                <ProductInputLabel>
                  {screenAmountMask(handler.productAmountInputValue)}
                </ProductInputLabel>
              </CouponOptionBox>
              <CouponOptionBox
                margtop={px2vw(10)}
                isHidden={!handler.activeCoupon}
              >
                <ProductInputLabel margright={px2vw(32)}>
                  Valor Promocional:
                </ProductInputLabel>
                <ProductInput
                  name="productPromotionalAmount"
                  width={px2vw(120)}
                  height={px2vw(30)}
                  margtop={px2vw(-6)}
                  maxLength="9"
                  placeholder="R$ 18,00"
                  value={handler.productPromotionalAmountInputValue}
                  onChange={(t) =>
                    handler.setProductPromotionalAmountInputValue(
                      amountMask(t.target.value)
                    )
                  }
                />
              </CouponOptionBox>
              <CouponOptionBox
                margtop={px2vw(10)}
                isHidden={!handler.flashCoupon}
              >
                <ProductInputLabel margright={px2vw(41)}>
                  Data de Validade:
                </ProductInputLabel>
                <ProductInput
                  name="productPromotionalValidityDate"
                  width={px2vw(120)}
                  height={px2vw(30)}
                  margtop={px2vw(-6)}
                  maxLength="10"
                  placeholder="00/00/0000"
                  value={handler.productPromotionalValidityDateInputValue}
                  onChange={(t) =>
                    handler.setProductPromotionalValidityDateInputValue(
                      dateMask(t.target.value)
                    )
                  }
                />
              </CouponOptionBox>
              <CouponOptionBox
                margtop={px2vw(10)}
                isHidden={!handler.flashCoupon}
              >
                <ProductInputLabel margright={px2vw(47)}>
                  Hora de término:
                </ProductInputLabel>
                <ProductInput
                  name="productPromotionalValidityTime"
                  width={px2vw(120)}
                  height={px2vw(30)}
                  margtop={px2vw(-6)}
                  maxLength="5"
                  placeholder="22:00"
                  value={handler.productPromotionalValidityTimeInputValue}
                  onChange={(t) =>
                    handler.setProductPromotionalValidityTimeInputValue(
                      timeMask(t.target.value)
                    )
                  }
                />
              </CouponOptionBox>
              <CouponOptionBox
                margtop={px2vw(10)}
                isHidden={!handler.flashCoupon}
              >
                <ProductInputLabel margright={px2vw(66)}>
                  Limite de Uso:
                </ProductInputLabel>
                <ProductInput
                  name="productPromotionalUseLimit"
                  width={px2vw(120)}
                  height={px2vw(30)}
                  margtop={px2vw(-6)}
                  placeholder="15"
                  min="1"
                  max="9999"
                  type="text"
                  value={handler.productPromotionalUseLimitInputValue}
                  onChange={(t) => {
                    handler.setProductPromotionalUseLimitInputValue(
                      onlyNumbers(t.target.value.substring(0, 4))
                    );
                  }}
                />
              </CouponOptionBox>
            </Col>
          </Row>
        </ProdutCouponBox>
      </Col>
      <Row margtop={px2vw(35)} width="auto">
        <ButtonImage
          src={require('../../../../assets/images/complete/complete_green.png')}
        />
        <ProductInputLabelRegular>
          Seu produto deve conter foto para a promoção ser ativada
        </ProductInputLabelRegular>
      </Row>
      <ModalActions>
        <ModalSecundaryButton
          margleft={px2vw(60)}
          marginRight={px2vw(20)}
          margtop={px2vw(35)}
          width={px2vw(290)}
          onClick={handler.handleCloseProductModal}
          disabled={handler.productModalLoading}
        >
          <ModalSecundaryButtonText>
            Cancelar
          </ModalSecundaryButtonText>
        </ModalSecundaryButton>
        <ModalPrimaryButton
          id="productCouponFinishButton"
          margleft={px2vw(20)}
          margtop={px2vw(35)}
          width={px2vw(290)}
          type="submit"
          disabled={handler.productModalLoading}
        >
          {handler.productModalLoading ? (
            <LoadingBox>
              <Spinner
                style={{
                  width: '1.8rem',
                  height: '1.8rem',
                  color: preto,
                }}
              />{' '}
            </LoadingBox>
          ) : (
            <ModalPrimaryButtonText>
              Concluir
            </ModalPrimaryButtonText>
          )}
        </ModalPrimaryButton>
      </ModalActions>
    </TabContent>
  );
}
